import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import ItemImageModal from './ItemImageModal'
import ItemAttachmentModal from './ItemAttachmentModal'
import ItemInventorysModal from './ItemInventoryModal'
import ReactTags from 'react-tag-autocomplete'
import ItemCustomFieldModal from './ItemCustomFieldModal'
import WarehouseDeleteItemModal from "../../warehouse/items/WarehouseDeleteItemModal"
import { fetchAPI } from "@/utility/NetworkUtils";
import { Stack, Chip } from "@mui/material";
class ItemForm extends React.Component {
  constructor(props) {
    super(props);
    //Temporary Variables
    const variantItem = (props.items||[]).find(i => i.id === props.item.variant_of_id);
    const currentRenter = props.renter ? props.renter : props.item.renter;
    const currentCategory = props.item.category;
    this.state = {
      itemImages: props.item.item_images || [],
      itemAttachments: props.item.item_attachments || [],
      itemVariants: props.item.variants || [],
      item: props.item,
      itemVariantOf: variantItem ? {label: variantItem.name, value: props.item.variant_of_id} : null,
      addVariantSelect: null,
      availableItemTags: [],
      itemTags: props.item.item_tags.map(tag => {return {id: tag.id, name: tag.tag}}),  
      itemRenter: currentRenter ? {value: currentRenter.id, label: currentRenter.name} : undefined,
      itemCategory: currentCategory ? {value: currentCategory.id, label: currentCategory.group_name + " - " + currentCategory.name} : {
        value: null,
        label: "Misc - Other"
      },
      itemInventoryCount: props.item.owned || 0,
      itemWarehouseCount: props.item.currently_in_warehouse || 0,
      itemPublic: props.item.public,
      itemCustomFields: props.item.custom_fields ? JSON.parse(props.item.custom_fields).fields : [],
      labelSelectedImage: {value: null, label: "Select an Image"},
      labelQuantity: null,
    }
    //Dont want to put this on the layout.
    //Due to outside linking to specific items.
    $("html").css("scroll-behavior", "smooth");

    this.deleteImage = this.deleteImage.bind(this);
    this.reorderImages = this.reorderImages.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.addImage = this.addImage.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.getVariantOptions = this.getVariantOptions.bind(this);
    this.linkVariantParent = this.linkVariantParent.bind(this);
    this.updateItemVariantOf = this.updateItemVariantOf.bind(this);
    this.unlinkVariantParent = this.unlinkVariantParent.bind(this);
    this.addVariant = this.addVariant.bind(this);
    this.updateAddVariantSelect = this.updateAddVariantSelect.bind(this);
    this.handleTagAddition = this.handleTagAddition.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateItemRenter = this.updateItemRenter.bind(this);
    this.updateInventoryCount = this.updateInventoryCount.bind(this);
    this.addCustomField = this.addCustomField.bind(this);
    this.updateCustomField = this.updateCustomField.bind(this);
    this.updateItemCategory = this.updateItemCategory.bind(this);
    this.updateItemPublicity = this.updateItemPublicity.bind(this);
    this.updateLabelSelectedImage = this.updateLabelSelectedImage.bind(this);
    this.updateLabelQuantity = this.updateLabelQuantity.bind(this);
    this.generateLabel = this.generateLabel.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    this.fetchItemTags(item.renter_id);
  }

  async fetchItemTags(renterId) {
    fetchAPI(`/webapi/item_tags${renterId ? "?renter_id=" + renterId : ""}`, (json)=> {
      if (!json.errors) {
        this.setState({availableItemTags: json.tags})
      } else {
        console.log({
          errors: json.errors
        })
      }
    });
  }

  deleteImage(id) {
    if(confirm("Are you sure you want to delete this image? This cannot be undone.")){
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");

      fetch( `/webapi/images/${id}` , {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState({itemImages: this.state.itemImages.filter(x=> x.id != id)});
          } else {
            alert(json.error);
          }
        });
    }
  }

  deleteAttachment(id) {
    if(confirm("Are you sure you want to delete this image? This cannot be undone.")){
        const cookies = new Cookies();
        const token = cookies.get("X-CSRF-Token");
        fetchAPI(`/webapi/attachments/${id}`, (json) => {
          if (json.error == null) {
            this.setState({itemAttachments: this.state.itemAttachments.filter(x=> x.id != id)});
          } else {
            alert(json.error);
          }
        },
        {
          method: 'DELETE',
        }
      );
    }
  }

  handleTagAddition (tag) {
    const { itemTags } = this.state
    const tags = [...itemTags, {
      name: tag.value
    }].filter((x,i,t)=> t.findIndex(y=> y.name === x.name) === i)
    this.setState({ itemTags: tags })
  }

  handleDelete (i) {
    const tags = this.state.itemTags.filter((item,index) => index !== i)
    this.setState({ itemTags: tags })
  }

  getRenterOptions() {
    const { renters } = this.props;
    return (renters||[]).map(renter => {return  {value: renter.id, label: renter.name}}).concat([{label: "Moonlite", value: null}])
  }

  getCategoryOptions() {
    const { categories } = this.props;
    const { itemRenter } = this.state;
    const itemRenterId = itemRenter ? itemRenter.value : null
    return categories.filter(x=> x.renter_id == itemRenterId).map(renter => {return  {value: renter.id, label: `${renter.group_name || "Misc" } - ${renter.name || "Other"}`}}).concat([{label: "Misc - Other", value: null}]).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
  }

  updateItemRenter(selectedOption) {
    this.fetchItemTags(selectedOption.value);
    this.setState({
      itemRenter: selectedOption,
      itemCategory: {
        value: null,
        label: "Misc - Other"
      },
    })
  }

  updateItemCategory(selectedOption) {
    this.setState({
      itemCategory: selectedOption,
    })
  }

  getAvailableTags() {
    const { availableItemTags,itemTags } = this.state;
    return availableItemTags.map(availableTag => {
      return {
        label: availableTag.tag,
        value: availableTag.tag
      }
    }).filter(x=> itemTags.map(x=>x.name).indexOf(x.label) == -1);
  }

  renderItemDetails(formikProps) {
    const { itemTags, itemRenter, itemCategory, itemPublic } = this.state;
    const { disablePriceToRent } = this.props;
    return (
      <div className="warehouse-card" id="item-details">
        <h3> Item Details </h3>
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <Field name="item[draft]" type="hidden" />
            <label> Name </label>
            <Field type="text" className="form-control" name="item[name]" placeholder="Ex: Ikea Kailax Shelf" required/>
            <label> Description </label>
            <Field type="textarea" component="textarea" rows="10" className="form-control" name="item[description]" style={{maxWidth:"100%", minWidth:"100%", width: "100%"}}/>
            <label> Purchase Link </label>
            <Field type="text" className="form-control" name="item[purchase_link]"/>
            <label> Warehouse Location </label>
            <Field type="text" className="form-control" name="item[warehouse_location]"/>
            <label> Tags </label>
            <Stack gap={2} sx={{background:"white"}} direction="horizontal">
              {itemTags.map((tag,i) => (
                <Chip
                  key={`chip-${tag.name}`}
                  label={tag.name}
                  onDelete={() => this.handleDelete(i)}
                />
              ))}
            </Stack>
            <div style={{marginTop: "10px"}}/>

            <CreatableSelect
              onChange={this.handleTagAddition}
              options={this.getAvailableTags()}
            />
            Select an existing tag, or type to create a new one
            <br/>
            {this.props.renter ? "" : (
              <>
                <label> Owner </label>
                <Select
                  options={this.getRenterOptions()}
                  defaultValue={{value:null,label:"Moonlite"}}
                  value={itemRenter}
                  disabled={this.props.renter}
                  onChange={this.updateItemRenter}
                  styles={{menu: (provided, state)=>({color: "black", background: "white"})}}
                />
              </>
            )}
            <label> Category </label>
            <Select
              options={this.getCategoryOptions()}
              defaultValue={{value:"Misc",label:"Other"}}
              value={itemCategory}
              onChange={this.updateItemCategory}
              styles={{menu: (provided, state)=>({color: "black", background: "white"})}}
            />

            <label> Public </label>
            <Select
              options={[{value: true, label: "True"},{value: false, label:"False"}]}
              defaultValue={{value:null,label:"Public"}}
              value={itemPublic ?
                {value: true, label: "True"}
                :
                {value: false, label:"False"}
              }
              onChange={this.updateItemPublicity}
              styles={{menu: (provided, state)=>({color: "black", background: "white"})}}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            {disablePriceToRent ? "" : (
              <>
                <label> Price To Rent </label>
                <Field name="item[rental_cost]" step="0.01" type="number" className="form-control" size="6" />
              </>
            )}

            <label> Price To Purchase </label>
            <Field name="item[purchase_cost]" step="0.01" type="number" className="form-control" size="6" />
            <hr />
            <label> Product Width(in.) </label>
            <Field className="form-control" step="0.01" type="number" name="item[product_width]" />
            <label> Product Height(in.) </label>
            <Field className="form-control" step="0.01" type="number" name="item[product_height]" />
            <label> Product Length(in.) </label>
            <Field className="form-control" step="0.01" type="number" name="item[product_length]" />
            <hr />
            <label> Carton Size (Responsible for overall size and pricing) </label>
            <label> Please ensure all dimensions are accounted for. </label>
            <label> Carton Width(in.) </label>
            <Field className="form-control" step="0.01" type="number" name="item[carton_width]" />
            <label> Carton Height(in.) </label>
            <Field className="form-control" step="0.01" type="number" name="item[carton_height]" />
            <label> Carton Length(in.) </label>
            <Field className="form-control" step="0.01" type="number" name="item[carton_length]" />
            <hr />
            <label> Weight(lbs.)</label>
            <Field className="form-control" step="0.01" type="number" name="item[weight]" />
          </div>
        </div>
      </div>
    )


  }

  reorderImages(formikProps, image, newIndex) {
    let { itemImages } = this.state;

    const oldIndex = itemImages.indexOf(image);
    if (oldIndex !== newIndex) {
      itemImages.splice(newIndex, 0, itemImages.splice(oldIndex, 1)[0]);
    }

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    
    fetch(`/items/v1/item_images/order`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify({
        ids: itemImages.map(image => image.id)
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })

    this.setState({
      itemImages
    });

  }

  renderImages(formikProps) {
    const { itemImages, item } = this.state;
    const renderedCurrentImages = itemImages.map((image,index) => (
      <tr className="item-form-image" key={`item-image-${image.id}`}>
        <td>
        <div style={{backgroundImage:`url("${image.image}")`}} className="item-form-image-preview" />
        </td>
        <td className="hidden-xs">
          {image.original_filename}
        </td>
        <td>
        </td>
        <td>
          <button className="btn btn-default" type="button" disabled={index === 0} onClick={()=>this.reorderImages(formikProps, image, index-1)}>
            ↑
          </button>
          <button className="btn btn-default" type="button" disabled={index === itemImages.length-1} onClick={()=>this.reorderImages(formikProps, image, index+1)}>
            ↓
          </button>
        </td>
        <td>
          <button type="button" className="btn btn-danger pull-right" onClick={() => this.deleteImage(image.id)}> Delete </button>
        </td>
      </tr>
    ));
    return (
      <div className="warehouse-card" id="images">
        <div className="row">
          <div className="col-xs-12">
            <h3> Images </h3>
            <p> Upload images here. 
              <br/>
              <br/>
              The image at the top will be used as the preview image on the website. 
              <br/>Images can be reordered by pressing the up and down arrow buttons.
            </p>
            <table className="item-form-image-table">
              <tbody>
                {renderedCurrentImages}
              </tbody>
            </table>
            <br />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ItemImageModal addImage={this.addImage} index={(itemImages.map(x=>x.order).sort().pop()||0)+1}  item={this.props.item}/>
          </div>
        </div>
      </div>
    );
  }

  renderAttachments(formikProps) {
    const { itemAttachments } = this.state;
    const renderedCurrentAttachments = itemAttachments.sort((a,b) => a.id > b.id ? 1 : -1).map((attachment,index) => (
      <tr className="item-form-attachment">
        <td className="hidden-xs">
          <a href={attachment.attachment}>
            {attachment.filename}
          </a>
        </td>
        <td className="hidden-xs">
          <a href={attachment.attachment}>
          {attachment.original_filename}
          </a>
        </td>
        <td>
          <button type="button" className="btn btn-danger pull-right" onClick={() => this.deleteAttachment(attachment.id)}> Delete </button>
          <ItemAttachmentModal addAttachment={this.addAttachment} attachment={attachment} item={this.props.item}/>
        </td>
      </tr>
    ));
    return (
      <div className="warehouse-card" id="attachments">
        <div className="row">
          <div className="col-xs-12">
            <h3> Attachments </h3>
            <p>
              Upload additional non-image files, such as manuals, specifications, or receipts here.
            </p>
            <table className="item-form-attachment-table">
              <tbody>
                {renderedCurrentAttachments}
              </tbody>
            </table>
            <br />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ItemAttachmentModal addAttachment={this.addAttachment} item={this.props.item} onSave={this.addNewAttachment}/>
          </div>
        </div>
      </div>
    );
  }


  renderVariants(formikProps) {
    const { itemVariants } = this.state;
    const { disableVariants } = this.props;
    if(!disableVariants) {
      return (
        <div className="warehouse-card" id="variants">
          <h3> Variants </h3>
          {this.renderVariantContent()}
        </div>
      );
    }
  }

  renderVariantContent() {
    const { item, itemVariants } = this.state;
    if(item.variant_of_id){
      return this.renderVariantOf()
    }
    if(itemVariants.length > 0){
      return this.renderHasVariants();
    }
    return this.renderNoVariants();
  }

  renderNoVariants(){
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12">
            Select a Parent Item:
          </div>
          <div className="col-xs-12">
            <Select  onChange={this.updateItemVariantOf} styles={{menu: (provided, state)=>({color: "black", background: "white"})}} options={this.getVariantOfOptions()} value={this.state.itemVariantOf} />
            <button onClick={this.linkVariantParent} type="button" className="btn btn-green"> Link </button>
          </div>
        </div>
        <h3 className="or-text text-center"> Or </h3>
        <div className="row">
          <div className="col-xs-12">
            Add a Variant:
          </div>
          <div className="col-xs-12">
            <Select styles={{menu: (provided, state)=>({color: "black", background: "white"})}} options={this.getVariantOptions()} value={this.state.addVariantSelect} onChange={this.updateAddVariantSelect}/>
            <br />
            <button onClick={this.addVariant} type="button" className="btn btn-green"> Add Variants </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  addVariant() {
    const { addVariantSelect, item } = this.state;
    if(addVariantSelect) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/warehouse/items/${addVariantSelect.value}/`, {
        method: 'PATCH',
        redirect: "manual",
        body: JSON.stringify({item: {variant_of_id: item.id}}),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            item.variants.push(json.item);
            this.setState({addVariantSelect: null, item: item});
          } else {
            alert(json.error);
          }
        });
      }
  }

  updateItemVariantOf(selectedOption){
    this.setState({
      itemVariantOf: selectedOption
    })
  }

  updateAddVariantSelect(selectedOption){
    this.setState({
      addVariantSelect: selectedOption
    })
  }

  updateItemPublicity(selectedOption){
    this.setState({
      itemPublic: selectedOption.value
    })
  }

  linkVariantParent() {
    const {itemVariantOf, item} = this.state;
    if(itemVariantOf) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/warehouse/items/${item.id}/`, {
          method: 'PATCH',
          redirect: "manual",
          body: JSON.stringify({item: {variant_of_id: itemVariantOf.value}}),
          headers: {
            "X-CSRF-Token": token,
            "Content-Type": 'application/json'
          }
        })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            item.variant_of_id = itemVariantOf.value;
            this.setState({item: item});
          } else {
            alert(json.error);
          }
        });
    }
  }

  unlinkVariantParent() {
    const { item } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/warehouse/items/${item.id}/`, {
        method: 'PATCH',
        redirect: "manual",
        body: JSON.stringify({item: {variant_of_id: null}}),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            item.variant_of_id = null;
            this.setState({item: item});
          } else {
            alert(json.error);
          }
        });
  }

  updateInventoryCount(new_count, new_warehouse_count) {
    this.setState({
      itemInventoryCount: new_count,
      itemWarehouseCount: new_warehouse_count
    })
  }

  getVariantOfOptions() {
    const { item } = this.state;
    const { items } = this.props;
    return (items||[]).filter(i => i.variant_of_id == null)
                .filter(i => i.id !== item.id)
                .map(i => {return {label: i.name, value: i.id}});
  }

  getVariantOptions() {
    const { item } = this.state;
    const { items } = this.props;
    const idsWithVariants = (items||[]).map(i => i.variant_of_id).filter((value, index, self) => { return self.indexOf(value) === index });

    return items.filter(i => !idsWithVariants.includes(i.id))
                .filter(i => i.variant_of_id == null)
                .filter(i => !item.variants.map(x=>x.id).includes(i.id))
                .filter(i => i.id !== item.id)
                .map(i => {return {label: i.name, value: i.id}});
  }

  renderVariantOf() {
    const { item } = this.state;
    const otherVariantsRows = this.props.items.filter(i => i.variant_of_id == item.variant_of_id).map(i =>
      <tr>
        <td><a href={`/warehouse/items/${i.id}/edit`}> {i.name} </a></td>
      </tr>
    );
    return (
      <div className="row">
        <div className="col-xs-12">
          Current Parent: <a href={`/warehouse/items/${this.state.item.variant_of_id}/edit`}> {this.props.items.find(i => i.id == this.state.item.variant_of_id).name} </a>
        </div>
        <div className="col-xs-12">
          Change Parent Item:
        </div>
        <div className="col-xs-12">
          <Select  onChange={this.updateItemVariantOf} styles={{menu: (provided, state)=>({color: "black", background: "white"})}} options={this.getVariantOfOptions()} value={this.state.itemVariantOf} />
          <button onClick={this.linkVariantParent} type="button" className="btn btn-green"> Update Link </button>
          <button onClick={this.unlinkVariantParent} type="button" className="btn btn-danger"> Unlink </button>
        </div>
        <div className="col-xs-12">
          <br />
          Other Variants:
          <table class="sibling-variant-table">
            <tbody>
              {otherVariantsRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderHasVariants() {
    const { item } = this.state;
    const variantRows = item.variants.map(variant =>
      <tr>
        <td><a href={`/warehouse/items/${variant.id}/edit`} target="_blank">{variant.name}</a></td>
        <td> <button type="button" className="btn btn-danger pull-right"> Unlink </button> </td>
      </tr>
    )
    return (
      <div className="row">
        <div className="col-xs-12">
          Add a Variant:
        </div>
        <div className="col-xs-12">
          <Select styles={{menu: (provided, state)=>({color: "black", background: "white"})}} options={this.getVariantOptions()} value={this.state.addVariantSelect} onChange={this.updateAddVariantSelect}/>
          <br />
          <button onClick={this.addVariant} type="button" className="btn btn-green"> Add Variant </button>
          <br />
        </div>
        <div className="col-xs-12">
          <label> Variants: </label>
          <table className="item-form-has-variants-table">
            <tbody>
              {variantRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  addImage(itemImage){
    this.setState({
      itemImages: this.state.itemImages.concat([itemImage])
    })
  }

  addAttachment(itemAttachment){
    this.setState({
      itemAttachments: this.state.itemAttachments.filter(attachment=> attachment.id != itemAttachment.id).concat([itemAttachment])
    })
  }


  renderInventory(formikProps) {
    const { itemInventoryCount, itemWarehouseCount } = this.state;
    const { disableInventoryLog } = this.props;
    return (
      <div className="warehouse-card" id="inventory">
        <h3> Item Inventory </h3>
        <h4>
        Currently Owned: {itemInventoryCount}
        <br/>
        Currently In Warehouse: {itemWarehouseCount}
        </h4>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ItemInventorysModal item={this.props.item} updateInventoryCount={this.updateInventoryCount}/>
          </div>
          <div className="col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl">
            <br />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ItemInventorysModal destroy={true} item={this.props.item} updateInventoryCount={this.updateInventoryCount}/>
          </div>
        </div>
        {!disableInventoryLog ? (
          <div className="row">
            <div className="col-xs-12">
              <br />
              <a href={`/warehouse/items/${this.props.item.id}/inventory`} className="pull-right"> Edit Inventory Log </a>
            </div>
          </div>
        ) : ""}
   
      </div>
    );
  }

  addCustomField(values) {
    const { itemCustomFields } = this.state;
    this.setState({
      itemCustomFields: itemCustomFields.concat([values])
    })
  }

  updateCustomField(index,values) {
    this.setState((state, props) => {
      const itemCustomFields = state.itemCustomFields;
      itemCustomFields[index] = values;

      state.itemCustomFields = itemCustomFields;
      return state;
    })
  }

  renderCustomFields(formikProps) {
    const { itemCustomFields } = this.state;
    const rows = itemCustomFields.map((field,index)=> (
      <tr key={`custom-field-${field.type}-index`}>
        <td>{field.name}</td>
        <td>{field.value}</td>
        <td>
          <ItemCustomFieldModal updateCustomField={this.updateCustomField} field={field} index={index} addCustomField={this.addCustomField}/>
        </td>
      </tr>
    ));
    return (
      <div className="warehouse-card" id="custom-fields">
        <h3> Custom Fields </h3>
        <p>
          Additional information such as color, count, or types can be input here.
        </p>
        <div className="row">
          <div className="col-xs-12">
            <table className="warehouse-basic-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            <br />
            <ItemCustomFieldModal addCustomField={this.addCustomField}/>
          </div>
        </div>
      </div>
    );
  }

  getLabelImageOptions(){
    const { itemImages } = this.state;
    return itemImages.map( image =>
      {return {value: image.id, image: image, label: image.original_filename}}
    )
  }

  updateLabelSelectedImage(selectedOption){
    this.setState({
      labelSelectedImage: selectedOption
    })
  }

  updateLabelQuantity(e){
    this.setState({
      labelQuantity: e.target.value
    })
  }

  renderLabels(formikProps) {
    const { labelSelectedImage, labelQuantity } = this.state;
    const { disableLabels } = this.props;
    if(!disableLabels) {
      return (
        <div className="warehouse-card" id="labels">
          <h3> Labels </h3>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <label> Select Image </label>
              <Select
                options={this.getLabelImageOptions()}
                value={labelSelectedImage}
                onChange={this.updateLabelSelectedImage}
                styles={{menu: (provided, state)=>({color: "black", background: "white"})}}
              />
              <br />
              <div className="label-image-preview">
                {(labelSelectedImage && labelSelectedImage.image) ? (<img src={labelSelectedImage.image.image}/>) : "Image Preview"}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <label>Quantity</label>
              <br />
              <input className="form-control" onChange={this.updateLabelQuantity} value={labelQuantity} type="number" step="1"/>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <br />
              <button type="button" class="btn btn-primary" onClick={this.generateLabel}> Generate Label </button>
            </div>
          </div>
        </div>
      )
    }

  }

  renderForm(formikProps) {
    const { item, disableLabels, disableVariants } = this.props;
    return (
      <div className="row warehouse-item-form-container">
        <Form className="users-form" id="event-edit-form" style={{display:"flex"}}>
          <div className="col-xs-12 col-sm-9">
            {this.renderItemDetails(formikProps)}
            {this.renderCustomFields(formikProps)}
            {this.renderImages(formikProps)}
            {this.renderAttachments(formikProps)}
            {this.renderVariants(formikProps)}
            {this.renderLabels(formikProps)}
            {this.renderInventory(formikProps)}
          </div>
          <div className="hidden-xs col-sm-3 warehouse-item-form-navigation-container">
            <div className="warehouse-card warehouse-item-form-navigation-container">
              <ul>
                <li><a href="#item-details"> Item Details </a></li>
                <li><a href="#custom-fields"> Custom Fields </a></li>
                <li><a href="#images"> Images </a></li>
                <li><a href="#attachments"> Attachments </a></li>
                {disableVariants ? "" : (<li><a href="#variants"> Variants </a></li>)}
                {disableLabels ? "" : (<li><a href="#labels"> Labels </a></li>)}
                <li><a href="#inventory"> Inventory </a></li>
              </ul>
              <button type="submit" style={{width: "100%"}} className="btn btn-primary"> Save </button>
              {item ? (
                <WarehouseDeleteItemModal outerStyle={{display:"block", width:"100%"}} item={item} button={
                  (
                    <>
                    <br/>
                    <button style={{width: "100%"}} type="button" className="btn btn-danger">
                      Delete
                    </button>
                    </>
                  )
                }/>
              ):""}


            </div>
          </div>
        </Form>
      </div>
    );
  }

  generateLabel(){
    const { item } = this.props;
    const { labelQuantity, labelSelectedImage} = this.state;

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/warehouse/items/${item.id}/generate_label`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({
        label: {
          item_image_id: labelSelectedImage.value,
          quantity: labelQuantity
        }
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then( res => res.blob() )
    .then( blob => {
      var file = window.URL.createObjectURL(blob);
      window.open(file, '_blank');
    });
  }

  render() {
    const { item, onSuccessfulSaveUrl} = this.props;
    return (
      <Formik
        initialValues={{
          item: {
            name: item.name || "",
            description: item.description || "",
            product_length: item.product_length || null,
            product_width: item.product_width || null,
            product_height: item.product_height || null,
            carton_length: item.carton_length || null,
            carton_width: item.carton_width || null,
            carton_height: item.carton_height || null,
            weight: item.weight || null,
            purchase_link: item.purchase_link || "",
            rental_cost: item.rental_cost || null,
            purchase_cost: item.purchase_cost || null,
            warehouse_location: item.warehouse_location || "",
            draft: false,
            public: item.public,
          }
        }}
        onSubmit={(values, actions) => {

          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          values["item"]["tags"] = this.state.itemTags.map(x =>
            x.name
          )
          values["item"]["renter_id"]=null;
          if(this.state.itemRenter) {
            values["item"]["renter_id"] = this.state.itemRenter.value;
          }
          if(this.state.itemCategory) {
            values["item"]["category_id"] = this.state.itemCategory.value;
          }
          values["item"]["custom_fields"] = JSON.stringify({fields: this.state.itemCustomFields});
          values["item"]["public"]=this.state.itemPublic;
          fetch(`/webapi/items/${item.id}/`, {
            method: 'PATCH',
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(onSuccessfulSaveUrl) {
                  window.location.replace(onSuccessfulSaveUrl);
                }else {
                  window.location.reload();
                }
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          this.renderForm(formikProps)
      )}/>
    );
  }
}

ItemForm.propTypes = {
  item: PropTypes.object.isRequired,
  renters: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default ItemForm;
